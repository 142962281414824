import { FunctionComponent, useEffect, useRef, useState } from "react";
import "./notificationMessages.css";
import icoReplacement from "../../../assets/images/ico_replacement.svg";
import icoAttention from "../../../assets/images/ico_attention.svg";
import icoCritical from "../../../assets/images/ico_critical.svg";
import icoInformation from "../../../assets/images/ico_information.svg";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { RowColor } from "../RowColor";
import { ANIMATION_TIMEOUT, getTextWidth } from "../../../utilities/helpers";
import useOnScreen from "../../useOnScreen";

export enum NotificationPrioType {
  error,
  warning,
  info,
  splitTrain,
  none,
}

export interface INotificationMessages {
  prioType: NotificationPrioType;
  messages: string[];
  backgroundColor: RowColor;
  isCompanion: boolean;
  isLufthansaTrain: boolean;
  isLandscape: boolean;
}

interface NotificationStateModel {
  prioType: NotificationPrioType;
  messages: string[];
  animKey: number;
}

const NotificationMessages: FunctionComponent<INotificationMessages> = (props) => {
  const calculateAnimationWidth = (isLufthansa: boolean, isLandscape: boolean) => {
    if (isLandscape) {
      return isLufthansa ? 962 : 1290;
    }
    return isLufthansa ? 1138 : 1560;
  };

  const [itemWidth] = useState(calculateAnimationWidth(props.isLufthansaTrain, props.isLandscape));

  const [font, setFont] = useState("42px db-screen-sans-regular, sans-serif");

  useEffect(() => {
    if (props.isLandscape) {
      setFont("44px db-screen-sans-regular, sans-serif");
    } else {
      setFont("42px db-screen-sans-regular, sans-serif");
    }
  }, [props.isLandscape]);

  const mainRef = useRef<HTMLDivElement>(null);
  const notificationRef = useRef<HTMLDivElement>(null);

  const previousStateRef = useRef<any>();

  // const visibilityRef = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(notificationRef);

  const [gradientOpacity, setGradientOpacity] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setGradientOpacity(isVisible ? 1 : 0);
    }, 2000);
  }, [isVisible]);

  const [stateModel, setStateModel] = useState<NotificationStateModel>({
    prioType: NotificationPrioType.none,
    messages: [],
    animKey: 0,
  });

  useEffect(() => {
    var currentStateObj = {
      type: props.prioType,
      messages: props.messages,
    };
    if (previousStateRef.current) {
      var previousState = JSON.stringify(previousStateRef.current);

      var currentState = JSON.stringify(currentStateObj);

      if (currentState !== previousState) {
        setStateModel({
          prioType: props.prioType,
          messages: props.messages,
          animKey: new Date().getTime(),
        });
      }

      previousStateRef.current = currentStateObj;
    } else {
      setStateModel({
        prioType: props.prioType,
        messages: props.messages,
        animKey: new Date().getTime(),
      });

      previousStateRef.current = currentStateObj;
    }
  }, [props.prioType, props.messages]);

  function getNotificationIcon(type: NotificationPrioType) {
    switch (type) {
      case NotificationPrioType.error:
        return <img className={props.isCompanion ? "notification-icon companion" : "notification-icon"} src={icoCritical} alt="critical"></img>;
      case NotificationPrioType.warning:
        return <img className={props.isCompanion ? "notification-icon companion" : "notification-icon"} src={icoReplacement} alt="warning"></img>;
      case NotificationPrioType.info:
        return <img className={props.isCompanion ? "notification-icon companion" : "notification-icon"} src={icoAttention} alt="info"></img>;
      case NotificationPrioType.splitTrain:
        return <img className={props.isCompanion ? "notification-icon companion" : "notification-icon"} src={icoInformation} alt="info"></img>;
      case NotificationPrioType.none:
        return <></>;
    }
  }

  function createMessageLayut(messages: string[]) {
    let messageText = "";

    messages.forEach((message) => {
      if (messageText === "") {
        messageText = messageText + `${message} `;
      } else {
        messageText = messageText + ` ---- ${message} `;
      }
    });

    const textWidth = getTextWidth(messageText, font);

    if (textWidth <= itemWidth || !isVisible) {
      return (
        <>
          <div className="notification-messages-host">
            <div className={props.isLandscape ? "notification-messages landscape notification-no-anim" : "notification-messages notification-no-anim"}>
              <span>{messageText}</span>
            </div>
          </div>
        </>
      );
    } else {
      const animationDuration = props.isLandscape ? (textWidth * 20) / itemWidth + "s" : (textWidth * 30) / itemWidth + "s";

      return (
        <>
          <div className="notification-messages-host">
            <div className={props.isLandscape ? "notification-messages landscape" : "notification-messages"} style={{ animationDuration: animationDuration }}>
              <span className="notification-anim">{messageText} ----&nbsp;</span>
              <span className="notification-anim">{messageText} ----&nbsp;</span>
            </div>
            <div className={props.backgroundColor === RowColor.white ? "notification-left-gradient" : "notification-left-gradient notification-gray"} style={{ opacity: gradientOpacity }}></div>
            <div className={props.backgroundColor === RowColor.white ? "notification-right-gradient" : "notification-right-gradient notification-gray-right"}></div>
          </div>
        </>
      );
    }
  }

  return (
    <div ref={notificationRef}>
      {props.prioType !== NotificationPrioType.none && (
        <SwitchTransition mode="out-in">
          <CSSTransition nodeRef={mainRef} classNames="display" timeout={ANIMATION_TIMEOUT} key={stateModel.animKey}>
            <div ref={mainRef} className="notification-row" style={{ marginLeft: props.isLufthansaTrain ? "422px" : "0px", marginTop: props.isCompanion ? "16px" : "16px" }}>
              {getNotificationIcon(stateModel.prioType)}
              {createMessageLayut(stateModel.messages)}
            </div>
          </CSSTransition>
        </SwitchTransition>
      )}
    </div>
  );
};

export default NotificationMessages;
