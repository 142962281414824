import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Departure } from "../../data/Departure";
import { RowColor } from "../../components/travelCenter/RowColor";
import { getTextWidth } from "../../utilities/helpers";
import TravelCenterBoardCompanionItem, { CompanionItemType, ICompanionTrain } from "../../components/travelCenter/boardItem/TravelCenterBoardCompanionItem";
import { INotificationMessages } from "../../components/travelCenter/notificationMessages/NotificationMessages";
import TravelCenterBoardItem from "../../components/travelCenter/boardItem/TravelCenterBoardItem";
import "./leftPanel.css";

type LeftPanelProps = {
  connections: Departure[];
};

const VISIBLE_ITEM_COUNT = 6;

const LeftPanel: FunctionComponent<LeftPanelProps> = (props) => {
  const PLATFORM_AREA_WIDTH = 170;

  const [departures, setDepartures] = useState<any[]>([]);
  const [headerShadowVisible, setHeaderShadowVisible] = useState(false);

  const cacheListRef = useRef<any[]>([]);

  useEffect(() => {
    let currentBackgroundSelector: RowColor = RowColor.white;
    var departureListItems: any[] = [];

    cacheListRef.current = cacheListRef.current.filter((item) => {
      return item.position.newPosition !== -1;
    });

    var maxPlatformLength = 0;

    for (let index = 0; index < VISIBLE_ITEM_COUNT; index++) {
      const element = props.connections[index];
      const platformLength = getTextWidth(element.platform, "72px db-screen-sans-bold, sans-serif");

      if (platformLength > maxPlatformLength && index < VISIBLE_ITEM_COUNT) {
        maxPlatformLength = platformLength;
      }
    }

    maxPlatformLength = Math.ceil(maxPlatformLength);

    var additionalPlatformOffset = 0;
    if (maxPlatformLength > PLATFORM_AREA_WIDTH) {
      additionalPlatformOffset = maxPlatformLength - PLATFORM_AREA_WIDTH;
    }

    var index = 0;
    var skippedTrainCount = 0;
    while (index < props.connections.length) {
      const conn = props.connections[index];

      var notification: INotificationMessages = {
        isLandscape: true,
        prioType: conn.notificationPrioType,
        messages: conn.notificationMessages,
        backgroundColor: RowColor.white,
        isCompanion: false,
        isLufthansaTrain: conn.lufthansa !== undefined,
      };

      if (currentBackgroundSelector === RowColor.white) {
        currentBackgroundSelector = RowColor.gray;
      } else {
        currentBackgroundSelector = RowColor.white;
      }

      // Find starting background color
      if (index === 0) {
        var initialCachedDepartures = cacheListRef.current.filter((item) => {
          return item.key === conn.departureID;
        });

        if (initialCachedDepartures.length > 0) {
          currentBackgroundSelector = initialCachedDepartures[0].currentBackgroundSelector;
        }
      }

      var isCompanionModeActive = false;
      var companionTrains: ICompanionTrain[] = [];
      var companionItemType: CompanionItemType = CompanionItemType.one_train_cut;
      var companionNotifications: INotificationMessages[] = [];

      if (conn.companions.length > 0) {
        isCompanionModeActive = true;

        const currentTrain: ICompanionTrain = {
          transportType: conn.type,
          transportCategory: "",
          transportNumber: conn.label,
          destinationName: conn.destination,
          lufthansaTrain: conn.lufthansa ?? "",
          differingDestinationName: conn.differingDestination,
          viaStations: conn.viaStops,
          canceled: conn.canceled,
        };

        companionTrains.push(currentTrain);

        for (let index = 0; index < conn.companions.length; index++) {
          const companionConnectionJourneyId = conn.companions[index];

          var companionTrainArray = props.connections.filter((item, index) => {
            return item.journeyID === companionConnectionJourneyId && index < VISIBLE_ITEM_COUNT;
          });

          if (companionTrainArray.length > 0) {
            const companionConnection = companionTrainArray[0];

            const companion: ICompanionTrain = {
              transportType: companionConnection.type,
              transportCategory: "",
              transportNumber: companionConnection.label,
              destinationName: companionConnection.destination,
              lufthansaTrain: companionConnection.lufthansa ?? "",
              differingDestinationName: companionConnection.differingDestination,
              viaStations: companionConnection.viaStops,
              canceled: companionConnection.canceled,
            };

            companionTrains.push(companion);
          }
        }

        if (companionTrains.length === 1 && index === VISIBLE_ITEM_COUNT - 1) {
          companionItemType = CompanionItemType.one_train_cut;
        } else if (companionTrains.length === 2 && conn.companions.length === 1) {
          companionItemType = CompanionItemType.two_trains_complete;
        } else if (companionTrains.length === 2 && conn.companions.length === 2 && index === VISIBLE_ITEM_COUNT - 2) {
          companionItemType = CompanionItemType.two_trains_cut;
        } else if (companionTrains.length === 3 && conn.companions.length === 2) {
          companionItemType = CompanionItemType.three_trains_complete;
        } else {
          isCompanionModeActive = false;
        }

        if (isCompanionModeActive) {
          var secondNotification = JSON.parse(JSON.stringify(notification));
          companionNotifications.push(notification);

          if (companionItemType === CompanionItemType.three_trains_complete) {
            companionNotifications.push(secondNotification);
          }
        }
      }

      var cachedDeparture = cacheListRef.current.filter((item) => {
        return item.key === conn.departureID;
      });
      var position;

      if (cachedDeparture.length === 0) {
        position = {
          newPosition: index - skippedTrainCount,
          oldPosition: cacheListRef.current.length === 0 ? index - skippedTrainCount : -1,
        };
      } else {
        position = {
          newPosition: index - skippedTrainCount,
          oldPosition: cachedDeparture[0].position.newPosition,
        };

        var itemIndex = cacheListRef.current.indexOf(cachedDeparture[0]);
        if (itemIndex !== -1) {
          cacheListRef.current.splice(itemIndex, 1);
        }
      }

      if (isCompanionModeActive) {
        const companionItem = {
          key: conn.departureID,
          platform: conn.platform,
          time: conn.canceled ? "" : conn.time,
          timeSchedule: conn.timeSchedule,
          platformSchedule: conn.platformSchedule,
          currentBackgroundSelector: currentBackgroundSelector,
          companionTrains: companionTrains,
          position: position,
          notifications: companionNotifications,
          type: companionItemType,
          mode: "companion",
          additionalPlatformOffset: additionalPlatformOffset,
        };

        departureListItems.push(companionItem);

        index = index + conn.companions.length + 1;
      } else {
        const departureListItem = {
          key: conn.departureID,
          time: conn.canceled ? "" : conn.time,
          timeSchedule: conn.timeSchedule,
          transportType: conn.type,
          transportCategory: "",
          transportNumber: conn.label,
          destinationName: conn.destination,
          viaStations: conn.viaStops,
          platform: conn.platform,
          platformSchedule: conn.platformSchedule,
          currentBackgroundSelector,
          lufthansaTrain: conn.lufthansa,
          differingDestination: conn.differingDestination,
          canceled: conn.canceled,
          notification: notification,
          position: position,
          mode: "single",
          additionalPlatformOffset: additionalPlatformOffset,
          replacedTrainNumber: conn.replacedTrainLabel,
        };
        departureListItems.push(departureListItem);

        index = index + 1;
      }
    }

    if (cacheListRef.current.length > 0) {
      var isOldFirstElementLeaving = false;

      cacheListRef.current.forEach((element: any) => {
        var position = {
          newPosition: -1,
          oldPosition: element.position.newPosition,
        };

        if (element.position.newPosition === 0) {
          isOldFirstElementLeaving = true;
        }
        var oldPos = element.position.oldPosition;
        element.position = position;
        departureListItems.splice(oldPos, 0, element);
      });

      if (isOldFirstElementLeaving) {
        setHeaderShadowVisible(true);

        setTimeout(() => {
          setHeaderShadowVisible(false);
        }, cacheListRef.current.length * 500);
      }
    }

    setDepartures(departureListItems);

    cacheListRef.current = departureListItems;
  }, [props.connections]);

  return (
    <div className="landscape-left-panel">
      <div className={headerShadowVisible ? "sub-header borderless" : "sub-header"}>
        <div className="column-name time">
          <div className="top">Zeit</div>
          <div className="bottom">Time</div>
        </div>
        <div className="column-name train">
          <div className="top">Zug</div>
          <div className="bottom">Train</div>
        </div>
        <div className="column-name destination">
          <div className="top">Nach</div>
          <div className="bottom">To</div>
        </div>
        <div className="column-name platform">
          <div className="top">Gleis</div>
          <div className="bottom">Plat.</div>
        </div>
      </div>
      <div className={headerShadowVisible ? "sub-header-shadow visible" : "sub-header-shadow"}></div>

      <div className="travel-center-list">
        {departures.map((item) => {
          if (item.mode === "single") {
            return (
              <TravelCenterBoardItem
                isVerticalAnimationAllowed={true}
                isLandscape={true}
                key={item.key}
                time={item.time}
                timeSchedule={item.timeSchedule}
                currentBackgroundSelector={item.currentBackgroundSelector}
                platform={item.platform}
                platformSchedule={item.platformSchedule}
                transportType={item.transportType}
                transportCategory={item.transportCategory}
                transportNumber={item.transportNumber}
                destinationName={item.destinationName}
                differingDestinationName={item.differingDestination}
                lufthansaTrain={item.lufthansaTrain}
                viaStations={item.viaStations}
                canceled={item.canceled}
                notification={item.notification}
                position={item.position}
                additionalPlatformOffset={item.additionalPlatformOffset}
                replacedTrainNumber={item.replacedTrainNumber}
              ></TravelCenterBoardItem>
            );
          } else {
            return (
              <TravelCenterBoardCompanionItem
                isVerticalAnimationAllowed={true}
                isLandscape={true}
                key={item.key}
                time={item.time}
                timeSchedule={item.timeSchedule}
                currentBackgroundSelector={item.currentBackgroundSelector}
                platform={item.platform}
                platformSchedule={item.platformSchedule}
                companionTrains={item.companionTrains}
                position={item.position}
                notifications={item.notifications}
                type={item.type}
                additionalPlatformOffset={item.additionalPlatformOffset}
              ></TravelCenterBoardCompanionItem>
            );
          }
        })}
      </div>
    </div>
  );
};

export default LeftPanel;
