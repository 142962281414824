import { FunctionComponent, useEffect, useRef, useState } from "react";
import "./paginationIndicator.css";

type PaginationIndicatorPropsType = {
  itemCount: number;
  currentItem: number;
};

const PaginationIndicator: FunctionComponent<PaginationIndicatorPropsType> = (props) => {
  const [selectedItem, setSelectedItem] = useState(-1);
  const initialLoadingRef = useRef(false);

  useEffect(() => {
    // For the initial animation on page load
    if (initialLoadingRef.current === false) {
      setTimeout(() => {
        setSelectedItem(props.currentItem);
        initialLoadingRef.current = true;
      }, 500);
    } else {
      setSelectedItem(props.currentItem);
    }
  }, [props.currentItem]);

  return (
    <div className="pagination-indicator-host">
      {Array.from(Array(props.itemCount).keys()).map((item) => {
        return (
          <div key={item} className={item === selectedItem ? "indicator-outer active" : "indicator-outer"}>
            <div className={item === selectedItem ? "indicator-inner active" : "indicator-inner"} />
          </div>
        );
      })}
    </div>
  );
};

export default PaginationIndicator;
