import { FunctionComponent, useRef, useEffect } from "react";
import { RowColor } from "../RowColor";

import { ANIMATION_TIMEOUT, getFormattedTime, getTimeDelta } from "../../../utilities/helpers";
import TrainChip from "../trainChip/TrainChip";
import "./travelCenterBoardItem.css";
import "./travelCenterBoardCompanionItem.css";
import ViaStops from "../viaStops/ViaStops";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import DestinationRow from "../destinationRow/DestinationRow";
import NotificationMessages, { INotificationMessages } from "../notificationMessages/NotificationMessages";

import twoTrainsCompleteBracket from "../../../assets/images/two_trains_complete_bracket.svg";
import two_trains_complete_no_icon from "../../../assets/images/two_trains_complete_no_icon.svg";
import one_train_cut_bracket from "../../../assets/images/one_train_cut_bracket.svg";
import one_train_cut_no_icon from "../../../assets/images/one_train_cut_no_icon.svg";
import three_trains_complete_bracket from "../../../assets/images/three_trains_complete_bracket.svg";
import three_trains_complete_no_icon from "../../../assets/images/three_trains_complete_no_icon.svg";
import two_trains_cut_bracket from "../../../assets/images/two_trains_cut_bracket.svg";

import twoTrainsCompleteBracket_landscape from "../../../assets/images/two_trains_complete_bracket_landscape.svg";
import two_trains_complete_no_icon_landscape from "../../../assets/images/two_trains_complete_no_icon_landscape.svg";
import one_train_cut_bracket_landscape from "../../../assets/images/one_train_cut_bracket_landscape.svg";
import one_train_cut_no_icon_landscape from "../../../assets/images/one_train_cut_no_icon_landscape.svg";
import three_trains_complete_bracket_landscape from "../../../assets/images/three_trains_complete_bracket_landscape.svg";
import three_trains_complete_no_icon_landscape from "../../../assets/images/three_trains_complete_no_icon_landscape.svg";
import two_trains_cut_bracket_landscape from "../../../assets/images/two_trains_cut_bracket_landscape.svg";
import two_trains_cut_no_icon_landscape from "../../../assets/images/two_trains_cut_no_icon_landscape.svg";
import one_train_last_bracket from "../../../assets/images/one_train_last_bracket.svg";
import two_trains_last_bracket from "../../../assets/images/two_trains_last_bracket.svg";
import two_trains_last_no_icon from "../../../assets/images/two_trains_last_no_icon.svg";

export enum CompanionItemType {
  two_trains_complete,
  three_trains_complete,
  one_train_cut,
  two_trains_cut,
  one_train_last,
  two_trains_last,
}

export interface ITravelCenterBoardCompanionItem {
  isVerticalAnimationAllowed: boolean;
  isLandscape: boolean;
  platform: string;
  time: string;
  timeSchedule: string;
  platformSchedule: string;
  currentBackgroundSelector: RowColor;
  companionTrains: ICompanionTrain[];
  position: any;
  notifications: INotificationMessages[];
  type: CompanionItemType;
  additionalPlatformOffset: number;
}

export interface ICompanionTrain {
  transportType: string;
  transportCategory: string;
  transportNumber: string;
  destinationName: string;
  differingDestinationName?: string;
  lufthansaTrain: string;
  viaStations: any[];
  canceled: boolean;
}

const TravelCenterBoardCompanionItem: FunctionComponent<ITravelCenterBoardCompanionItem> = (props) => {
  const mainRef = useRef<HTMLDivElement>(null);
  const firstTimeLoadRef = useRef<boolean>(true);
  const timeRef = useRef(null);
  const platformRef = useRef(null);

  useEffect(() => {
    const isFirstTime = firstTimeLoadRef.current;
    firstTimeLoadRef.current = false;

    setTimeout(
      () => {
        if (mainRef.current) {
          const height = props.isLandscape ? 294 : 282;
          const maxVisiblePosition = props.isLandscape ? 6 : 12;

          const transitionDuration = props.isVerticalAnimationAllowed ? Math.max(Math.abs(props.position.oldPosition - props.position.newPosition) * 500, 500) + "ms" : "0ms";
          const delay = "0ms";
          mainRef.current.style.zIndex = props.position.newPosition === -1 || props.position.oldPosition === -1 ? "0" : (props.position.newPosition + 1).toString();
          mainRef.current.style.top = props.position.newPosition * height + "px";
          mainRef.current.style.opacity = props.position.newPosition === -1 || props.position.newPosition > maxVisiblePosition ? "0" : "1";
          mainRef.current.style.transition =
            "top " + transitionDuration + " linear " + delay + ", opacity " + transitionDuration + " linear " + delay + " ,background-color " + transitionDuration + " linear " + delay;
          mainRef.current.style.backgroundColor = props.currentBackgroundSelector === RowColor.white ? "white" : "#f0f3f5";
        }
      },
      props.isVerticalAnimationAllowed && !isFirstTime ? 3000 : 0
    );
  }, [props.position, props.currentBackgroundSelector, props.isVerticalAnimationAllowed, props.isLandscape]);

  function getTimeClassName() {
    if (props.time !== "") {
      return getTimeDelta(props.time, props.timeSchedule) > 299000 ? "time updated" : "time";
    } else {
      return "time";
    }
  }

  function getBracketImage(type: CompanionItemType, isLandscape: boolean, hasMessage: boolean): string {
    switch (type) {
      case CompanionItemType.one_train_last:
        return one_train_last_bracket;
      case CompanionItemType.one_train_cut:
        if (isLandscape) {
          if (hasMessage) {
            return one_train_cut_bracket_landscape;
          } else {
            return one_train_cut_no_icon_landscape;
          }
        } else {
          if (hasMessage) {
            return one_train_cut_bracket;
          } else {
            return one_train_cut_no_icon;
          }
        }
      case CompanionItemType.two_trains_last:
        if (hasMessage) {
          return two_trains_last_bracket;
        } else {
          return two_trains_last_no_icon;
        }
      case CompanionItemType.two_trains_cut:
        if (isLandscape) {
          if (hasMessage) {
            return two_trains_cut_bracket_landscape;
          } else {
            return two_trains_cut_no_icon_landscape;
          }
        } else {
          return two_trains_cut_bracket;
        }
      case CompanionItemType.two_trains_complete:
        if (isLandscape) {
          if (hasMessage) {
            return twoTrainsCompleteBracket_landscape;
          } else {
            return two_trains_complete_no_icon_landscape;
          }
        } else {
          if (hasMessage) {
            return twoTrainsCompleteBracket;
          } else {
            return two_trains_complete_no_icon;
          }
        }
      case CompanionItemType.three_trains_complete:
        if (isLandscape) {
          if (hasMessage) {
            return three_trains_complete_bracket_landscape;
          } else {
            return three_trains_complete_no_icon_landscape;
          }
        } else {
          if (hasMessage) {
            return three_trains_complete_bracket;
          } else {
            return three_trains_complete_no_icon;
          }
        }
    }
  }

  function generateMainLayout() {
    switch (props.type) {
      case CompanionItemType.one_train_last:
        return (
          <>
            <img alt="" className="companion-bracket-top" src={getBracketImage(props.type, props.isLandscape, props.notifications[0].messages.length !== 0)}></img>
            <div className="destination-row">
              <TrainChip
                transportType={props.companionTrains[0].transportType}
                // transportCategory={props.companionTrains[0].transportCategory}
                transportNumber={props.companionTrains[0].transportNumber}
                lufthansaTrain={props.companionTrains[0].lufthansaTrain}
                canceled={props.companionTrains[0].canceled}
                rowColor={props.currentBackgroundSelector}
              />

              <DestinationRow
                isLandscape={props.isLandscape}
                destinationName={props.companionTrains[0].destinationName}
                differingDestinationName={props.companionTrains[0].differingDestinationName}
                backgroundColor={props.currentBackgroundSelector}
                additionalPlatformOffset={props.additionalPlatformOffset}
              ></DestinationRow>
            </div>
            <ViaStops
              isLandscape={props.isLandscape}
              isLufthansaTrain={props.companionTrains[0].lufthansaTrain !== ""}
              viaStations={props.companionTrains[0].viaStations}
              backgroundColor={props.currentBackgroundSelector}
            ></ViaStops>
            <NotificationMessages
              isLandscape={props.isLandscape}
              prioType={props.notifications[0].prioType}
              messages={props.notifications[0].messages}
              backgroundColor={props.currentBackgroundSelector}
              isCompanion={false}
              isLufthansaTrain={false}
            ></NotificationMessages>
          </>
        );

      case CompanionItemType.one_train_cut:
        return (
          <>
            <img alt="" className="companion-bracket" src={getBracketImage(props.type, props.isLandscape, props.notifications[0].messages.length !== 0)}></img>
            <div className="destination-row">
              <TrainChip
                transportType={props.companionTrains[0].transportType}
                // transportCategory={props.companionTrains[0].transportCategory}
                transportNumber={props.companionTrains[0].transportNumber}
                lufthansaTrain={props.companionTrains[0].lufthansaTrain}
                canceled={props.companionTrains[0].canceled}
                rowColor={props.currentBackgroundSelector}
              />

              <DestinationRow
                isLandscape={props.isLandscape}
                destinationName={props.companionTrains[0].destinationName}
                differingDestinationName={props.companionTrains[0].differingDestinationName}
                backgroundColor={props.currentBackgroundSelector}
                additionalPlatformOffset={props.additionalPlatformOffset}
              ></DestinationRow>
            </div>
            <ViaStops
              isLandscape={props.isLandscape}
              isLufthansaTrain={props.companionTrains[0].lufthansaTrain !== ""}
              viaStations={props.companionTrains[0].viaStations}
              backgroundColor={props.currentBackgroundSelector}
            ></ViaStops>
            <NotificationMessages
              isLandscape={props.isLandscape}
              prioType={props.notifications[0].prioType}
              messages={props.notifications[0].messages}
              backgroundColor={props.currentBackgroundSelector}
              isCompanion={true}
              isLufthansaTrain={false}
            ></NotificationMessages>
          </>
        );

      case CompanionItemType.two_trains_last:
        return (
          <>
            <img alt="" className="companion-bracket-top" src={getBracketImage(props.type, props.isLandscape, props.notifications[0].messages.length !== 0)}></img>
            <div className="destination-row">
              <TrainChip
                transportType={props.companionTrains[0].transportType}
                transportNumber={props.companionTrains[0].transportNumber}
                lufthansaTrain={props.companionTrains[0].lufthansaTrain}
                canceled={props.companionTrains[0].canceled}
                rowColor={props.currentBackgroundSelector}
              />

              <DestinationRow
                isLandscape={props.isLandscape}
                destinationName={props.companionTrains[0].destinationName}
                differingDestinationName={props.companionTrains[0].differingDestinationName}
                backgroundColor={props.currentBackgroundSelector}
                additionalPlatformOffset={props.additionalPlatformOffset}
              ></DestinationRow>
            </div>
            <div className={props.isLandscape ? "companion-via-stops landscape" : "companion-via-stops"}>
              <ViaStops
                isLandscape={props.isLandscape}
                isLufthansaTrain={props.companionTrains[0].lufthansaTrain !== ""}
                viaStations={props.companionTrains[0].viaStations}
                backgroundColor={props.currentBackgroundSelector}
              ></ViaStops>
            </div>
            <div className={props.isLandscape ? "companion-first-notification landscape" : "companion-first-notification"}>
              <NotificationMessages
                isLandscape={props.isLandscape}
                prioType={props.notifications[0].prioType}
                messages={props.notifications[0].messages}
                backgroundColor={props.currentBackgroundSelector}
                isCompanion={true}
                isLufthansaTrain={false}
              ></NotificationMessages>
            </div>
            <div className="destination-row">
              <TrainChip
                transportType={props.companionTrains[1].transportType}
                // transportCategory={props.companionTrains[1].transportCategory}
                transportNumber={props.companionTrains[1].transportNumber}
                lufthansaTrain={props.companionTrains[1].lufthansaTrain}
                canceled={props.companionTrains[1].canceled}
                rowColor={props.currentBackgroundSelector}
              />

              <DestinationRow
                isLandscape={props.isLandscape}
                destinationName={props.companionTrains[1].destinationName}
                differingDestinationName={props.companionTrains[1].differingDestinationName}
                backgroundColor={props.currentBackgroundSelector}
                additionalPlatformOffset={props.additionalPlatformOffset}
              ></DestinationRow>
            </div>
            <div className={props.isLandscape ? "companion-via-stops landscape" : "companion-via-stops"}>
              <ViaStops isLandscape={props.isLandscape} isLufthansaTrain={false} viaStations={props.companionTrains[1].viaStations} backgroundColor={props.currentBackgroundSelector}></ViaStops>
            </div>
          </>
        );
      case CompanionItemType.two_trains_cut:
        return (
          <>
            <img alt="" className="companion-bracket" src={getBracketImage(props.type, props.isLandscape, props.notifications[0].messages.length !== 0)}></img>
            <div className="destination-row">
              <TrainChip
                transportType={props.companionTrains[0].transportType}
                transportNumber={props.companionTrains[0].transportNumber}
                lufthansaTrain={props.companionTrains[0].lufthansaTrain}
                canceled={props.companionTrains[0].canceled}
                rowColor={props.currentBackgroundSelector}
              />

              <DestinationRow
                isLandscape={props.isLandscape}
                destinationName={props.companionTrains[0].destinationName}
                differingDestinationName={props.companionTrains[0].differingDestinationName}
                backgroundColor={props.currentBackgroundSelector}
                additionalPlatformOffset={props.additionalPlatformOffset}
              ></DestinationRow>
            </div>
            <div className={props.isLandscape ? "companion-via-stops landscape" : "companion-via-stops"}>
              <ViaStops
                isLandscape={props.isLandscape}
                isLufthansaTrain={props.companionTrains[0].lufthansaTrain !== ""}
                viaStations={props.companionTrains[0].viaStations}
                backgroundColor={props.currentBackgroundSelector}
              ></ViaStops>
            </div>
            <div className={props.isLandscape ? "companion-first-notification landscape" : "companion-first-notification"}>
              <NotificationMessages
                isLandscape={props.isLandscape}
                prioType={props.notifications[0].prioType}
                messages={props.notifications[0].messages}
                backgroundColor={props.currentBackgroundSelector}
                isCompanion={true}
                isLufthansaTrain={false}
              ></NotificationMessages>
            </div>
            <div className="destination-row">
              <TrainChip
                transportType={props.companionTrains[1].transportType}
                // transportCategory={props.companionTrains[1].transportCategory}
                transportNumber={props.companionTrains[1].transportNumber}
                lufthansaTrain={props.companionTrains[1].lufthansaTrain}
                canceled={props.companionTrains[1].canceled}
                rowColor={props.currentBackgroundSelector}
              />

              <DestinationRow
                isLandscape={props.isLandscape}
                destinationName={props.companionTrains[1].destinationName}
                differingDestinationName={props.companionTrains[1].differingDestinationName}
                backgroundColor={props.currentBackgroundSelector}
                additionalPlatformOffset={props.additionalPlatformOffset}
              ></DestinationRow>
            </div>
            <div className={props.isLandscape ? "companion-via-stops landscape" : "companion-via-stops"}>
              <ViaStops isLandscape={props.isLandscape} isLufthansaTrain={false} viaStations={props.companionTrains[1].viaStations} backgroundColor={props.currentBackgroundSelector}></ViaStops>
            </div>
          </>
        );
      case CompanionItemType.two_trains_complete:
        return (
          <>
            <img alt="" className="companion-bracket" src={getBracketImage(props.type, props.isLandscape, props.notifications[0].messages.length !== 0)}></img>
            <div className="destination-row">
              <TrainChip
                transportType={props.companionTrains[0].transportType}
                // transportCategory={props.companionTrains[0].transportCategory}
                transportNumber={props.companionTrains[0].transportNumber}
                lufthansaTrain={props.companionTrains[0].lufthansaTrain}
                canceled={props.companionTrains[0].canceled}
                rowColor={props.currentBackgroundSelector}
              />

              <DestinationRow
                isLandscape={props.isLandscape}
                destinationName={props.companionTrains[0].destinationName}
                differingDestinationName={props.companionTrains[0].differingDestinationName}
                backgroundColor={props.currentBackgroundSelector}
                additionalPlatformOffset={props.additionalPlatformOffset}
              ></DestinationRow>
            </div>
            <div className={props.isLandscape ? "companion-via-stops landscape" : "companion-via-stops"}>
              <ViaStops
                isLandscape={props.isLandscape}
                isLufthansaTrain={props.companionTrains[0].lufthansaTrain !== ""}
                viaStations={props.companionTrains[0].viaStations}
                backgroundColor={props.currentBackgroundSelector}
              ></ViaStops>
            </div>
            <div className={props.isLandscape ? "companion-first-notification landscape" : "companion-first-notification"}>
              <NotificationMessages
                isLandscape={props.isLandscape}
                prioType={props.notifications[0].prioType}
                messages={props.notifications[0].messages}
                backgroundColor={props.currentBackgroundSelector}
                isCompanion={true}
                isLufthansaTrain={false}
              ></NotificationMessages>
            </div>
            <div className="destination-row">
              <TrainChip
                transportType={props.companionTrains[1].transportType}
                // transportCategory={props.companionTrains[1].transportCategory}
                transportNumber={props.companionTrains[1].transportNumber}
                lufthansaTrain={props.companionTrains[1].lufthansaTrain}
                canceled={props.companionTrains[1].canceled}
                rowColor={props.currentBackgroundSelector}
              />

              <DestinationRow
                isLandscape={props.isLandscape}
                destinationName={props.companionTrains[1].destinationName}
                differingDestinationName={props.companionTrains[1].differingDestinationName}
                backgroundColor={props.currentBackgroundSelector}
                additionalPlatformOffset={props.additionalPlatformOffset}
              ></DestinationRow>
            </div>
            <div className={props.isLandscape ? "companion-via-stops landscape" : "companion-via-stops"}>
              <ViaStops
                isLandscape={props.isLandscape}
                isLufthansaTrain={props.companionTrains[1].lufthansaTrain !== ""}
                viaStations={props.companionTrains[1].viaStations}
                backgroundColor={props.currentBackgroundSelector}
              ></ViaStops>
            </div>
          </>
        );
      case CompanionItemType.three_trains_complete:
        return (
          <>
            <img alt="" className="companion-bracket" src={getBracketImage(props.type, props.isLandscape, props.notifications[0].messages.length !== 0)}></img>

            <div className="destination-row">
              <TrainChip
                transportType={props.companionTrains[0].transportType}
                // transportCategory={props.companionTrains[0].transportCategory}
                transportNumber={props.companionTrains[0].transportNumber}
                lufthansaTrain={props.companionTrains[0].lufthansaTrain}
                canceled={props.companionTrains[0].canceled}
                rowColor={props.currentBackgroundSelector}
              />

              <DestinationRow
                isLandscape={props.isLandscape}
                destinationName={props.companionTrains[0].destinationName}
                differingDestinationName={props.companionTrains[0].differingDestinationName}
                backgroundColor={props.currentBackgroundSelector}
                additionalPlatformOffset={props.additionalPlatformOffset}
              ></DestinationRow>
            </div>
            <div className={props.isLandscape ? "companion-via-stops landscape" : "companion-via-stops"}>
              <ViaStops
                isLandscape={props.isLandscape}
                isLufthansaTrain={props.companionTrains[0].lufthansaTrain !== ""}
                viaStations={props.companionTrains[0].viaStations}
                backgroundColor={props.currentBackgroundSelector}
              ></ViaStops>
            </div>
            <div className="companion-second-notification">
              <NotificationMessages
                isLandscape={props.isLandscape}
                prioType={props.notifications[0].prioType}
                messages={props.notifications[0].messages}
                backgroundColor={props.currentBackgroundSelector}
                isCompanion={true}
                isLufthansaTrain={false}
              ></NotificationMessages>
            </div>
            <div className="destination-row">
              <TrainChip
                transportType={props.companionTrains[1].transportType}
                // transportCategory={props.companionTrains[1].transportCategory}
                transportNumber={props.companionTrains[1].transportNumber}
                lufthansaTrain={props.companionTrains[1].lufthansaTrain}
                canceled={props.companionTrains[1].canceled}
                rowColor={props.currentBackgroundSelector}
              />

              <DestinationRow
                isLandscape={props.isLandscape}
                destinationName={props.companionTrains[1].destinationName}
                differingDestinationName={props.companionTrains[1].differingDestinationName}
                backgroundColor={props.currentBackgroundSelector}
                additionalPlatformOffset={props.additionalPlatformOffset}
              ></DestinationRow>
            </div>
            <div className={props.isLandscape ? "companion-via-stops landscape" : "companion-via-stops"}>
              <ViaStops
                isLandscape={props.isLandscape}
                isLufthansaTrain={props.companionTrains[1].lufthansaTrain !== ""}
                viaStations={props.companionTrains[1].viaStations}
                backgroundColor={props.currentBackgroundSelector}
              ></ViaStops>
            </div>
            <div className="companion-second-notification">
              <NotificationMessages
                isLandscape={props.isLandscape}
                prioType={props.notifications[1].prioType}
                messages={props.notifications[1].messages}
                backgroundColor={props.currentBackgroundSelector}
                isCompanion={true}
                isLufthansaTrain={false}
              ></NotificationMessages>
            </div>

            <div className="destination-row">
              <TrainChip
                transportType={props.companionTrains[2].transportType}
                // transportCategory={props.companionTrains[2].transportCategory}
                transportNumber={props.companionTrains[2].transportNumber}
                lufthansaTrain={props.companionTrains[2].lufthansaTrain}
                canceled={props.companionTrains[2].canceled}
                rowColor={props.currentBackgroundSelector}
              />

              <DestinationRow
                isLandscape={props.isLandscape}
                destinationName={props.companionTrains[2].destinationName}
                differingDestinationName={props.companionTrains[2].differingDestinationName}
                backgroundColor={props.currentBackgroundSelector}
                additionalPlatformOffset={props.additionalPlatformOffset}
              ></DestinationRow>
            </div>
            <div className={props.isLandscape ? "companion-via-stops landscape" : "companion-via-stops"}>
              <ViaStops
                isLandscape={props.isLandscape}
                isLufthansaTrain={props.companionTrains[2].lufthansaTrain !== ""}
                viaStations={props.companionTrains[2].viaStations}
                backgroundColor={props.currentBackgroundSelector}
              ></ViaStops>
            </div>
          </>
        );
    }
  }

  return (
    <div
      ref={mainRef}
      className={props.isLandscape ? "travel-center-board-item landscape" : "travel-center-board-item"}
      style={{ height: props.isLandscape ? props.companionTrains.length * 294 : props.companionTrains.length * 282 }}
    >
      <div className={props.type === CompanionItemType.one_train_cut ? "time-holder" : "time-holder"}>
        <div className="timeSchedule bold">{getFormattedTime(new Date(props.timeSchedule))}</div>
        <SwitchTransition mode="out-in">
          <CSSTransition nodeRef={timeRef} classNames="display" timeout={ANIMATION_TIMEOUT} key={props.time}>
            <div ref={timeRef} className={getTimeClassName()}>
              {props.time !== "" ? getFormattedTime(new Date(props.time)) : ""}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
      <div className="data-holder">{generateMainLayout()}</div>
      <SwitchTransition mode="out-in">
        <CSSTransition nodeRef={platformRef} classNames="display" timeout={ANIMATION_TIMEOUT} key={props.platform}>
          <div ref={platformRef} className={props.platform === props.platformSchedule ? "platform bold" : "platform bold updated"}>
            {props.platform}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default TravelCenterBoardCompanionItem;
