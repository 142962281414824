import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { getTextWidth } from "../../../utilities/helpers";
import { RowColor } from "../RowColor";
import "./viaStops.css";
import useOnScreen from "../../useOnScreen";

export interface ViaStop {
  name: string;
  canceled: boolean;
}

interface IViaStops {
  isLandscape: boolean;
  viaStations: ViaStop[];
  backgroundColor: RowColor;
  isLufthansaTrain: boolean;
}

const ViaStops: FunctionComponent<IViaStops> = (props) => {
  const ref = useRef<HTMLDivElement>(null);

  const isVisible = useOnScreen(ref);

  const [viaOpacity, setViaOpacity] = useState(0);

  const [font, setFont] = useState("42px db-screen-sans-regular, sans-serif");

  useEffect(() => {
    if (props.isLandscape) {
      setFont("44px db-screen-sans-regular, sans-serif");
    } else {
      setFont("42px db-screen-sans-regular, sans-serif");
    }
  }, [props.isLandscape]);

  useEffect(() => {
    setTimeout(() => {
      setViaOpacity(isVisible ? 1 : 0);
    }, 2000);
  }, [isVisible]);

  const calculateAnimationWidth = (isLufthansa: boolean, isLandscape: boolean) => {
    if (isLandscape) {
      return isLufthansa ? 1014 : 1428;
    }
    return isLufthansa ? 1183 : 1605;
  };

  const [itemWidth] = useState(calculateAnimationWidth(props.isLufthansaTrain, props.isLandscape));

  function createViaStops() {
    let viaText = "";

    var viaStops = [];
    for (let index = 0; index < props.viaStations.length; index++) {
      const viaStation = props.viaStations[index];
      var nameToAdd = viaStation.name.toString();
      if (viaText === "") viaText = nameToAdd;
      else viaText = viaText + ", " + nameToAdd;

      if (viaStation.canceled) {
        viaStops.push(
          <span key={index + "_canceled"} className="canceled-via-stop">
            {nameToAdd}
          </span>
        );
      } else {
        viaStops.push(<React.Fragment key={index}>{nameToAdd}</React.Fragment>);
      }

      if (index !== props.viaStations.length - 1) {
        viaStops.push(<React.Fragment key={index + "_comma"}>, </React.Fragment>);
      }
    }

    if (viaStops.length === 0) {
      viaStops.push(<span key={0}>-</span>);
    }

    const textWidth = getTextWidth(viaText, font);
    if (textWidth < itemWidth || !isVisible) {
      return (
        <div ref={ref} className={props.isLandscape ? "via-stops-row landscape" : "via-stops-row"} style={{ marginLeft: props.isLufthansaTrain ? "422px" : "0px" }}>
          <div className="via via-no-anim">{viaStops}</div>
        </div>
      );
    } else {
      const animationDuration = props.isLandscape ? (textWidth * 20) / itemWidth + "s" : (textWidth * 30) / itemWidth + "s";
      return (
        <div ref={ref} className={props.isLandscape ? "via-stops-row landscape" : "via-stops-row"} style={{ marginLeft: props.isLufthansaTrain ? "422px" : "0px" }}>
          <div className="via" style={{ animationDuration: animationDuration }}>
            <span className="via-anim">{viaStops} --&nbsp;</span>
            <span className="via-anim">{viaStops} --&nbsp;</span>
          </div>
          <div className={props.backgroundColor === RowColor.white ? "via-left-gradient" : "via-left-gradient via-gray"} style={{ opacity: viaOpacity }}></div>
          <div className={props.backgroundColor === RowColor.white ? "via-right-gradient" : "via-right-gradient via-gray-right"}></div>
        </div>
      );
    }
  }
  return createViaStops();
};

export default ViaStops;
