import { FunctionComponent, useEffect, useRef } from "react";
import { ANIMATION_TIMEOUT, getFormattedTime, getTimeDelta } from "../../../utilities/helpers";
import TrainChip from "../trainChip/TrainChip";
import "./travelCenterBoardItem.css";
import ViaStops from "../viaStops/ViaStops";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import DestinationRow from "../destinationRow/DestinationRow";
import NotificationMessages, { INotificationMessages } from "../notificationMessages/NotificationMessages";
import { RowColor } from "../RowColor";

interface ITravelCenterBoardItem {
  isLandscape: boolean;
  isVerticalAnimationAllowed: boolean;
  platform: string;
  time: string;
  timeSchedule: string;
  platformSchedule: string;
  currentBackgroundSelector: RowColor;
  transportType: string;
  transportCategory: string;
  transportNumber: string;
  destinationName: string;
  differingDestinationName?: string;
  lufthansaTrain: string;
  viaStations: any[];
  canceled: boolean;
  notification: INotificationMessages;
  position: any;
  additionalPlatformOffset: number;
  replacedTrainNumber?: string;
}

const TravelCenterBoardItem: FunctionComponent<ITravelCenterBoardItem> = (props) => {
  const mainRef = useRef<HTMLDivElement>(null);
  const firstTimeLoadRef = useRef<boolean>(true);
  const timeRef = useRef(null);
  const platformRef = useRef(null);

  function getTimeClassName() {
    if (props.time !== "") {
      return getTimeDelta(props.time, props.timeSchedule) > 299000 ? "time updated" : "time";
    } else {
      return "time";
    }
  }

  useEffect(() => {
    const isFirstTime = firstTimeLoadRef.current;
    firstTimeLoadRef.current = false;

    setTimeout(
      () => {
        if (mainRef.current) {
          const height = props.isLandscape ? 294 : 282;

          const maxVisiblePosition = props.isLandscape ? 6 : 12;

          const transitionDuration = props.isVerticalAnimationAllowed ? Math.max(Math.abs(props.position.oldPosition - props.position.newPosition) * 500, 500) + "ms" : "0ms";
          const delay = "0ms";
          mainRef.current.style.zIndex = props.position.newPosition === -1 || props.position.oldPosition === -1 ? "0" : (props.position.newPosition + 1).toString();
          mainRef.current.style.top = props.position.newPosition * height + "px";
          mainRef.current.style.opacity = props.position.newPosition === -1 || props.position.newPosition > maxVisiblePosition ? "0" : "1";
          mainRef.current.style.transition =
            "top " + transitionDuration + " linear " + delay + ", opacity " + transitionDuration + " linear " + delay + " ,background-color " + transitionDuration + " linear " + delay;
          mainRef.current.style.backgroundColor = props.currentBackgroundSelector === RowColor.white ? "white" : "#f0f3f5";
        }
      },
      props.isVerticalAnimationAllowed && !isFirstTime ? 3000 : 0
    );
    // }
  }, [props.position, props.currentBackgroundSelector, props.isVerticalAnimationAllowed, props.isLandscape]);
  return (
    <div ref={mainRef} className={props.isLandscape ? "travel-center-board-item landscape" : "travel-center-board-item"}>
      <div className="time-holder">
        <div className="time-schedule bold">{getFormattedTime(new Date(props.timeSchedule))}</div>
        <SwitchTransition mode="out-in">
          <CSSTransition nodeRef={timeRef} classNames="display" timeout={ANIMATION_TIMEOUT} key={props.time}>
            <div ref={timeRef} className={getTimeClassName()}>
              {props.time !== "" ? getFormattedTime(new Date(props.time)) : ""}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
      <div className="data-holder">
        <div className="destination-row">
          <TrainChip
            transportType={props.transportType}
            // transportCategory={props.transportCategory}
            transportNumber={props.transportNumber}
            lufthansaTrain={props.lufthansaTrain}
            canceled={props.canceled}
            rowColor={props.currentBackgroundSelector}
            replacedTrainNumber={props.replacedTrainNumber}
          />

          <DestinationRow
            isLandscape={props.isLandscape}
            destinationName={props.destinationName}
            differingDestinationName={props.differingDestinationName}
            backgroundColor={props.currentBackgroundSelector}
            additionalPlatformOffset={props.additionalPlatformOffset}
          ></DestinationRow>
        </div>

        <ViaStops
          isLandscape={props.isLandscape}
          viaStations={props.viaStations}
          backgroundColor={props.currentBackgroundSelector}
          isLufthansaTrain={props.lufthansaTrain !== "" && props.lufthansaTrain !== undefined}
        ></ViaStops>

        <NotificationMessages
          isLandscape={props.isLandscape}
          prioType={props.notification.prioType}
          messages={props.notification.messages}
          backgroundColor={props.currentBackgroundSelector}
          isCompanion={false}
          isLufthansaTrain={props.lufthansaTrain !== "" && props.lufthansaTrain !== undefined}
        ></NotificationMessages>
      </div>
      <SwitchTransition mode="out-in">
        <CSSTransition nodeRef={platformRef} classNames="display" timeout={ANIMATION_TIMEOUT} key={props.platform}>
          <div ref={platformRef} className={props.platform === props.platformSchedule ? "platform bold" : "platform bold updated"}>
            {props.platform}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default TravelCenterBoardItem;
