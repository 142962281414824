export enum ErrorCase {
  NO_INTERNET,
  WRONG_PARAMETER_EVA_NUMBER,
  WRONG_PARAMATER_FILTER_TRANSPORT,
  WRONG_PARAMETER_STATION_GROUP,
  WRONG_PARAMATER_TIMESPAN,
  WRONG_PARAMETER_PAGES,
  NO_DEPARTURES,
  NO_CONNECTION,
  GENERAL_ERROR,
}
