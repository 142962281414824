import { useRef, useEffect, useState, FunctionComponent, useCallback } from "react";
import { Departure } from "../data/Departure";
import { useInterval } from "../utilities/customReactHooks";
import LeftPanel from "./left/LeftPanel";
import RightPanel from "./right/RightPanel";
import fetchConnections from "../api/ConnectionRequest";
import Header from "../components/Header";
import { ErrorCase } from "../data/ErrorCase";
import "./landscapeMode.css";

type LandscapeModeTypes = {
  urlParams: string;
  appMode: string;
  maxPages: number;
  errorCase: ErrorCase | null;
  timeSpan: string;
};

const LandscapeMode: FunctionComponent<LandscapeModeTypes> = (props) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [leftPanelConnections, setLeftPanelConnections] = useState<Departure[]>([]);
  const [rightPanelConnections, setRightPanelConnections] = useState<Departure[]>([]);
  const [stationName, setStationName] = useState("");
  const [maxPages, setMaxPages] = useState(3);
  const [queryCounter, setQueryCounter] = useState(0);

  const [isLoaded, setLoaded] = useState(false);

  let fetchedConnections = useRef<Departure[]>([]);

  const [errorCase, setErrorCase] = useState(props.errorCase);

  const fetchData = useCallback(() => {
    if (props.urlParams === "") {
      return;
    }

    fetchConnections(props.urlParams, props.appMode, false, props.timeSpan)
      .then((departureBoard) => {
        if (departureBoard.departures.length === 0) {
          setErrorCase(ErrorCase.NO_DEPARTURES);
          return;
        } else {
          setErrorCase(null);
        }

        var maxPages = (departureBoard.departures.length - 6) / 6;
        maxPages = Math.floor(maxPages);
        maxPages = Math.min(maxPages, props.maxPages);

        setMaxPages(maxPages);

        fetchedConnections.current = departureBoard.departures;

        if (isLoaded === false) {
          setStationName(departureBoard.stationName);

          setLeftPanelConnections(fetchedConnections.current.slice(0, 10));
          setRightPanelConnections(fetchedConnections.current.slice(6));
        }

        setLoaded(true);
      })
      .catch(() => {
        setErrorCase(ErrorCase.NO_CONNECTION);
      });
  }, [isLoaded, props.appMode, props.urlParams, props.maxPages, props.timeSpan]);

  useEffect(() => {
    setErrorCase(props.errorCase);
  }, [props.errorCase]);

  useEffect(() => {
    fetchData();
  }, [props.urlParams, fetchData]);

  useInterval(() => {
    setQueryCounter((queryCounter + 1) % 4);
    setCurrentPage((currentPage + 1) % maxPages);
  }, 15000);

  useEffect(() => {
    setLeftPanelConnections(fetchedConnections.current.slice(0, 10));

    if (currentPage === 0) {
      setRightPanelConnections(fetchedConnections.current.slice(6));
    }
  }, [currentPage, fetchData]);

  useEffect(() => {
    if (queryCounter === 3) {
      setTimeout(() => {
        fetchData();
      }, 10000);
    }
  }, [queryCounter, fetchData]);

  function generateErrorLayout(error: ErrorCase) {
    let errorTitle, errorSubline;

    switch (error) {
      case ErrorCase.NO_INTERNET:
        errorTitle = "Keine Internetverbindung";
        errorSubline = (
          <div>
            Wir können keine Abfahrten anzeigen,
            <br />
            da aktuell keine Internetverbindung besteht.
          </div>
        );
        break;
      case ErrorCase.WRONG_PARAMETER_EVA_NUMBER:
        errorTitle = "Falsche URL-Parameter";
        errorSubline = (
          <div>
            Für den Parameter evaNumbers sind nur 7-stellige ganze Zahlen gültig.
            <br />
            <br />
            Mehrere Nummern können getrennt durch Kommas übergeben werden.
          </div>
        );
        break;
      case ErrorCase.WRONG_PARAMATER_FILTER_TRANSPORT:
        errorTitle = "Falsche URL-Parameter";
        errorSubline = (
          <div>
            Für den Parameter filterTransports sind nur diese Werte gültig: ‘HIGH_SPEED_TRAIN, INTERCITY_TRAIN, INTER_REGIONAL_TRAIN, REGIONAL_TRAIN, CITY_TRAIN, SUBWAY, TRAM, BUS, FERRY, FLIGHT, CAR,
            TAXI, SHUTTLE, BIKE, SCOOTER, WALK, UNKNOWN’.
            <br />
            <br />
            Mehrere Werte können getrennt durch Kommas übergeben werden.
          </div>
        );
        break;
      case ErrorCase.WRONG_PARAMETER_STATION_GROUP:
        errorTitle = "Falsche URL-Parameter";
        errorSubline = (
          <div>
            Für den Parameter includeStationGroup sind nur
            <br />
            true oder false gültige Werte.
          </div>
        );
        break;
      case ErrorCase.WRONG_PARAMATER_TIMESPAN:
        errorTitle = "Falsche URL-Parameter";
        errorSubline = "Für den Parameter timeSpan ist nur ein Wert zwischen 60 und 240 gültig.";
        break;
      case ErrorCase.WRONG_PARAMETER_PAGES:
        errorTitle = "Falsche URL-Parameter";
        errorSubline = "Für den Parameter pages ist nur ein Wert zwischen 2 und 6 gültig.";
        break;
      case ErrorCase.NO_DEPARTURES:
        errorTitle = "Nichts gefunden";
        errorSubline = "Es wurden keine Abfahrten gefunden.";
        break;
      case ErrorCase.NO_CONNECTION:
        errorTitle = "Keine Verbindung zur API";
        errorSubline = (
          <div>
            Es können keine Abfahrten angezeigt werden,
            <br />
            da aktuell keine Verbindung zur RIS-API besteht.
          </div>
        );
        break;
      default:
        errorTitle = "Etwas ist schief gelaufen";
        errorSubline = "Weitere Details zu dem Fehler sind leider nicht verfügbar.";
        break;
    }

    return (
      <div data-testid="error-box-holder" className="error-box-holder">
        <div className="error-box">
          {/* <img src={icoError} alt="error" /> */}
          <svg width="200px" height="200px" viewBox="0 0 144 144">
            <title>ico_error</title>
            <g id="ico_error" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
              <path
                d="M72,0 C111.764502,0 144,32.235498 144,72 C144,111.764502 111.764502,144 72,144 C32.235498,144 0,111.764502 0,72 C0,32.235498 32.235498,0 72,0 Z M46.5857864,37.4142136 L37.4142136,46.5857864 C36.633165,47.366835 36.633165,48.633165 37.4142136,49.4142136 L60,72 L37.4142136,94.5857864 C36.6742728,95.3257272 36.6353285,96.5012114 37.2973808,97.2869988 L37.4142136,97.4142136 L46.5857864,106.585786 C47.366835,107.366835 48.633165,107.366835 49.4142136,106.585786 L49.4142136,106.585786 L72,84 L94.5857864,106.585786 C95.366835,107.366835 96.633165,107.366835 97.4142136,106.585786 L106.585786,97.4142136 C107.366835,96.633165 107.366835,95.366835 106.585786,94.5857864 L84,72 L106.585786,49.4142136 C107.325727,48.6742728 107.364671,47.4987886 106.702619,46.7130012 L106.585786,46.5857864 L97.4142136,37.4142136 C96.633165,36.633165 95.366835,36.633165 94.5857864,37.4142136 L94.5857864,37.4142136 L72,60 L49.4142136,37.4142136 C48.633165,36.633165 47.366835,36.633165 46.5857864,37.4142136 Z"
                id="Combined-Shape"
                fill="#AFB4BB"
                fill-rule="nonzero"
              ></path>
            </g>
          </svg>
          <div className="title">{errorTitle}</div>
          <div className="subline">{errorSubline}</div>
        </div>
      </div>
    );
  }

  return (
    <>
      {isLoaded ? (
        <>
          <Header stationName={stationName} errorState={0} orientation={"landscape"} />

          <div className="landscape-mode">
            {errorCase !== null ? (
              generateErrorLayout(errorCase)
            ) : (
              <>
                <LeftPanel connections={leftPanelConnections} />
                <RightPanel connections={rightPanelConnections} indicator={currentPage} maxPages={maxPages}></RightPanel>
              </>
            )}
          </div>
        </>
      ) : errorCase !== null ? (
        <>
          <Header stationName={stationName} errorState={0} orientation={"landscape"} />

          <div className="landscape-mode">{generateErrorLayout(errorCase)}</div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default LandscapeMode;
