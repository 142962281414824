import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useInterval } from "../../../utilities/customReactHooks";
import "./trainChip.css";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { ANIMATION_TIMEOUT } from "../../../utilities/helpers";
import { RowColor } from "../RowColor";

interface ITrainChip {
  transportType: string;
  // transportCategory: string;
  transportNumber: string;
  lufthansaTrain?: string;
  canceled: boolean;
  rowColor: RowColor;
  replacedTrainNumber?: string;
}

const TrainChip: FunctionComponent<ITrainChip> = (props) => {
  const [isFlipped, setFlipped] = useState(false);
  const [interval, setInterval] = useState(6000);

  const chipRef = useRef(null);

  const isInitialFlip = useRef(false);

  const [isLufthansaTrain] = useState(props.lufthansaTrain !== "" && props.lufthansaTrain !== undefined);

  useEffect(() => {
    if (!isInitialFlip.current) {
      isInitialFlip.current = true;
    } else {
      if (isFlipped) {
        setInterval(4000);
      } else {
        setInterval(6000);
      }
    }
  }, [isFlipped]);

  useInterval(async () => {
    setFlipped(!isFlipped);
  }, interval);

  function createTrainChip() {
    var trainChipClassName = "";
    switch (props.transportType) {
      case "HIGH_SPEED_TRAIN":
        trainChipClassName = "ice";
        break;
      case "INTERCITY_TRAIN":
        trainChipClassName = "ec";
        break;
      case "INTER_REGIONAL_TRAIN":
        trainChipClassName = "regio";
        break;
      case "REGIONAL_TRAIN":
        trainChipClassName = "regio";
        break;
      case "CITY_TRAIN":
        trainChipClassName = "city-train";
        break;
      case "SUBWAY":
        trainChipClassName = "subway";
        break;
      case "TRAM":
        trainChipClassName = "tram";
        break;
      case "BUS":
        trainChipClassName = "bus";
        break;
      case "STB":
        trainChipClassName = "stb";
        break;
      case "ERSATZ":
        trainChipClassName = "ersatz";
        break;
      default:
        break;
    }

    function getCanceledBgColor() {
      if (props.rowColor === RowColor.white) {
        return "white-chip";
      } else {
        return "gray-chip";
      }
    }

    if (props.replacedTrainNumber === undefined) {
      return (
        <SwitchTransition>
          <CSSTransition nodeRef={chipRef} classNames={"display"} timeout={ANIMATION_TIMEOUT} key={props.canceled ? 0 : 1}>
            <div ref={chipRef} className={isLufthansaTrain ? `train-chip-holder lufthansa ${props.canceled ? "canceled" : ""}` : "train-chip-holder"}>
              <div className={props.canceled ? "train-chip bold canceled " + getCanceledBgColor() : "train-chip bold " + trainChipClassName}>
                <span>&nbsp;{props.transportNumber}&nbsp;</span>
              </div>
              {props.lufthansaTrain !== "" && props.lufthansaTrain !== undefined ? (
                <div className={props.canceled ? "lufthansa train-chip bold canceled " + getCanceledBgColor() : "lufthansa train-chip bold"}>
                  <span>&nbsp;{props.lufthansaTrain}&nbsp;</span>
                </div>
              ) : (
                <></>
              )}
            </div>
          </CSSTransition>
        </SwitchTransition>
      );
    } else {
      return (
        <SwitchTransition>
          <CSSTransition nodeRef={chipRef} classNames={"display"} timeout={ANIMATION_TIMEOUT} key={props.canceled ? 2 : 3}>
            {props.lufthansaTrain !== "" && props.lufthansaTrain !== undefined ? (
              <div ref={chipRef} className={isFlipped ? "train-chip-holder is-flipped" : "train-chip-holder lufthansa"}>
                <div className={"train-chip bold " + trainChipClassName}>
                  <span>&nbsp;{props.transportNumber}&nbsp;</span>
                </div>

                <div className={props.canceled ? "lufthansa train-chip bold canceled " + getCanceledBgColor() : "lufthansa train-chip bold"}>
                  <span>&nbsp;{props.lufthansaTrain}&nbsp;</span>
                </div>

                <div className={"train-chip bold canceled replaced " + getCanceledBgColor()}>
                  <span>&nbsp;{props.replacedTrainNumber}&nbsp;</span>
                </div>
              </div>
            ) : (
              <div ref={chipRef} className={isFlipped ? "train-chip-holder is-flipped" : "train-chip-holder"}>
                <div className={"train-chip bold " + trainChipClassName}>
                  <span>&nbsp;{props.transportNumber}&nbsp;</span>
                </div>
                {props.lufthansaTrain !== "" && props.lufthansaTrain !== undefined ? (
                  <div className={props.canceled ? "lufthansa train-chip bold canceled " + getCanceledBgColor() : "lufthansa train-chip bold"}>
                    <span>&nbsp;{props.lufthansaTrain}&nbsp;</span>
                  </div>
                ) : (
                  <></>
                )}
                <div className={"train-chip bold canceled replaced " + getCanceledBgColor()}>
                  <span>&nbsp;{props.replacedTrainNumber}&nbsp;</span>
                </div>
              </div>
            )}
          </CSSTransition>
        </SwitchTransition>
      );
    }
  }

  return createTrainChip();
};

export default TrainChip;
