import { FunctionComponent, useEffect, useRef, useState } from "react";
import { getTextWidth } from "../../../utilities/helpers";
import { RowColor } from "../RowColor";
import "./destinationRow.css";
import useOnScreen from "../../useOnScreen";
interface IDestinationRow {
  destinationName: string;
  differingDestinationName?: string;
  backgroundColor: RowColor;
  additionalPlatformOffset: number;
  isLandscape: boolean;
}

const DestinationRow: FunctionComponent<IDestinationRow> = (props) => {
  const [font, setFont] = useState("72px db-screen-sans-bold, sans-serif");

  useEffect(() => {
    if (props.isLandscape) {
      setFont("72px db-screen-sans-bold, sans-serif");
    } else {
      setFont("70px db-screen-sans-bold, sans-serif");
    }
  }, [props.isLandscape]);

  const destinationRef = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(destinationRef);

  const [itemWidth] = useState(props.isLandscape ? 920 - props.additionalPlatformOffset : 1155 - props.additionalPlatformOffset);

  function createLayout(): JSX.Element {
    var displayText = props.destinationName;
    var marginBetweenDestinations = 0;
    if (props.differingDestinationName) {
      displayText = " " + props.destinationName + " " + props.differingDestinationName;
      marginBetweenDestinations = 50;
    }

    var textWidth = getTextWidth(displayText, font) + marginBetweenDestinations;

    if (textWidth > itemWidth && isVisible) {
      return (
        <>
          <div ref={destinationRef} className="destination-host" style={{ width: itemWidth }}>
            {textWidth > itemWidth && <div className={props.backgroundColor === RowColor.gray ? "left-gradient gradient-gray" : "left-gradient"}></div>}

            <div className="destination-bouncer">
              {props.differingDestinationName ? (
                <>
                  <div className={"destination-name differed bold"}>&nbsp;{props.destinationName}&nbsp;</div>
                  <div className="destination-name differing bold">{props.differingDestinationName}</div>
                </>
              ) : (
                <div className={props.isLandscape ? "destination-name landscape bold" : "destination-name bold"}>{props.destinationName}</div>
              )}
            </div>
            {textWidth > itemWidth && <div className={props.backgroundColor === RowColor.gray ? "right-gradient gradient-gray" : "right-gradient"}></div>}
          </div>
        </>
      );
    } else {
      return (
        <div ref={destinationRef} className="destination-host" style={{ width: itemWidth }}>
          <div className="destination-bouncer no-animation">
            {props.differingDestinationName ? (
              <>
                <div className={"destination-name differed bold"}>&nbsp;{props.destinationName}&nbsp;</div>
                <div className="destination-name differing bold">{props.differingDestinationName}</div>
              </>
            ) : (
              <div className={props.isLandscape ? "destination-name landscape bold" : "destination-name bold"}>{props.destinationName}</div>
            )}
          </div>
        </div>
      );
    }
  }

  return createLayout();
};

export default DestinationRow;
