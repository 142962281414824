import { useEffect, useState } from "react";
import { getFormattedHourAndMinute, getTextWidth } from "../utilities/helpers";
import "./Header.css";

const Header = (props) => {
  const [currentHour, setCurrentHour] = useState(getFormattedHourAndMinute(new Date()).hour);
  const [currentMinute, setCurrentMinute] = useState(getFormattedHourAndMinute(new Date()).minute);

  useEffect(() => {
    const intervalID = setInterval(() => {
      const time = getFormattedHourAndMinute(new Date());

      setCurrentHour(time.hour);
      setCurrentMinute(time.minute);
    }, 1000);

    return () => clearInterval(intervalID);
  }, [props]);

  function createStationNameLayout(orientation) {
    if (props.errorState === 0) {
      const stationNameWidth = getTextWidth(props.stationName, "76px db-screen-sans-bold, sans-serif");

      if (stationNameWidth > 860 && orientation === "portrait") {
        return (
          <div className="station-host">
            <div className={"left-gradient"}></div>
            <div className="station animated">{props.stationName}</div>
            <div className={"right-gradient"}></div>
          </div>
        );
      } else {
        return (
          <div className="station-host">
            <div className="station">{props.stationName}</div>
          </div>
        );
      }
    } else {
      return <></>;
    }
  }

  return (
    <div className="header">
      <div className="data-holder">
        <div data-testid="current-time" className="time bold">
          <span>{currentHour}</span>
          <div className="blink">:</div>
          <span>{currentMinute}</span>
        </div>
        <div className="title bold">Abfahrten</div>
        <div className="translated-title">Departures</div>
        {createStationNameLayout(props.orientation)}
      </div>
    </div>
  );
};

export default Header;
