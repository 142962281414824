export const getEnv = (name) => {
  return window.configs?.[name] || process.env[name];
};

export const padNumber = (number) => {
  return String("00" + number).slice(-2);
};

export const isStringNumber = (value) => {
  return /^\d+$/.test(value);
};

export const getFormattedTime = (time) => {
  const [hours, minutes] = time
    .toLocaleTimeString("de-DE", {
      timeZone: "Europe/Berlin",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    })
    .split(":");
  return `${hours}:${minutes}`;
};

export const getFormattedHourAndMinute = (time) => {
  const [hours, minutes] = time
    .toLocaleTimeString("de-DE", {
      timeZone: "Europe/Berlin",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    })
    .split(":");
  return { hour: hours, minute: minutes };
};

export const getFormattedDate = (date) => {
  const [day, month, year] = date.toLocaleDateString("de-DE", { timeZone: "Europe/Berlin" }).split(".");
  const [hours, minutes, seconds] = date
    .toLocaleTimeString("de-DE", {
      timeZone: "Europe/Berlin",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
    .split(":");
  return `${year}-${padNumber(month)}-${padNumber(day)}T${hours}:${minutes}:${seconds}Z`;
};

export const checkRange = (value, minValue, maxValue) => {
  const integerValue = parseInt(value, 10);
  if (integerValue >= minValue && integerValue < maxValue + 1) {
    return true;
  } else {
    return false;
  }
};

export const getTimeDelta = (time, timeSchedule) => {
  const plannedTime = new Date(time);
  const scheduledTime = new Date(timeSchedule);
  return plannedTime.getTime() - scheduledTime.getTime();
};

// To sort Zugteilung connections so that they always appear in groups
export const sortZugteilungConnections = (completeConn) => {
  var index = 0;
  while (index < completeConn.length) {
    const connection = completeConn[index];
    if (connection.travelsWith.length > 0) {
      for (let travelsWithIndex = 0; travelsWithIndex < connection.travelsWith.length; travelsWithIndex++) {
        const companionConnection = connection.travelsWith[travelsWithIndex];

        var companionTrainArray = completeConn.filter((item) => {
          return item.journeyID === companionConnection.journeyID;
        });

        if (companionTrainArray.length > 0) {
          const companionTrain = companionTrainArray[0];

          const fromIndex = completeConn.indexOf(companionTrain);
          if (fromIndex !== -1) {
            const toIndex = index + 1;
            const element = completeConn.splice(fromIndex, 1)[0];
            completeConn.splice(toIndex, 0, element);
            index += 1;
          }
        }
      }
    }

    index += 1;
  }

  return completeConn;
};

export const ANIMATION_TIMEOUT = 400;

export function getTextWidth(text, font) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if (context) {
    context.font = font;
    return context.measureText(text).width;
  }
  return -1;
}
