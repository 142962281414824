import { NotificationPrioType } from "../components/travelCenter/notificationMessages/NotificationMessages";
import { ViaStop } from "../components/travelCenter/viaStops/ViaStops";
import { Departure } from "../data/Departure";
import { getEnv, getFormattedDate, sortZugteilungConnections } from "../utilities/helpers";

interface DepartureBoard {
  stationName: string;
  departures: Departure[];
}

export default function fetchConnections(params: string, appMode: string, secondAttempt: boolean, timeSpan: string): Promise<DepartureBoard> {
  const apiKey = appMode === "rz" ? getEnv("REACT_APP_DB_RZ_API_KEY") : getEnv("REACT_APP_DB_API_KEY");
  const clientId = appMode === "rz" ? getEnv("REACT_APP_DB_RZ_CLIENT_ID") : getEnv("REACT_APP_DB_CLIENT_ID");

  let queryForAPI: string;

  queryForAPI = getEnv("REACT_APP_QUERY_FOR_API");

  queryForAPI += params;

  var formattedEndDate;

  const endDate = new Date();
  endDate.setHours(endDate.getHours() + Number(timeSpan) / 60);
  formattedEndDate = getFormattedDate(endDate);

  queryForAPI += `&timeEnd=${formattedEndDate}&sortBy=TIME_SCHEDULE`;

  const path = new URL(queryForAPI).pathname;
  const paths = path.split("/");
  const evaNumbers = paths[paths.length - 1];
  const evaNumber = evaNumbers.split(",")[0];

  return fetch(queryForAPI, {
    method: "GET",
    headers: {
      Accept: "*/*",
      "DB-Api-Key": apiKey,
      "DB-Client-Id": clientId,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      var data = json.departures;

      if (data.length < 12 && !secondAttempt) {
        return fetchConnections(params, appMode, true, "600");
      }

      data = mergeCanceledAndReplacementTrains(data);

      data = sortZugteilungConnections(data);

      var departures: Departure[] = [];
      var stationName = "";

      for (let index = 0; index < data.length; index++) {
        const element = data[index];

        if (stationName === "" && element.station.evaNumber === evaNumber) {
          stationName = element.station.name;
        }

        const notification = createNotifications(element);

        const companionTrains = element.travelsWith.map((it: any) => {
          return it.journeyID;
        });

        let replacedTrainNumbers: string | undefined = "";
        element.replacementFor.forEach((train: any) => (replacedTrainNumbers = replacedTrainNumbers + `${train.category} ${chooseNumberOrLine(train)}`));

        if (replacedTrainNumbers === "") {
          replacedTrainNumbers = undefined;
        }

        const viaStops: ViaStop[] = element.transport.via.map((via: any) => {
          return { name: via.name, canceled: element.canceled ? false : via.canceled };
        });

        let type: string;

        if (element.transport.replacementTransport) {
          type = "ERSATZ";
        } else if (element.transport.type === "UNKNOWN" && element.transport.category === "STB") {
          type = "STB";
        } else {
          type = element.transport.type;
        }

        const lufthansa = getLufthansaTrain(element.messages, element.codeshares ? element.codeshares : []);

        var differingDestination: string | undefined = undefined;

        if (element.transport) {
          if (element.transport.differingDestination) {
            differingDestination = element.transport.differingDestination.name;
          }
        }

        const departure: Departure = {
          journeyID: element.journeyID,
          departureID: element.departureID,
          timeSchedule: element.timeSchedule,
          time: element.time,
          platformSchedule: element.platformSchedule,
          platform: element.platform,
          label: `${element.transport.category} ${chooseNumberOrLine(element.transport)}`,
          type: type,
          destination: element.transport.destination.name,
          canceled: element.canceled,
          notificationPrioType: notification.prioType,
          notificationMessages: notification.messages,
          companions: companionTrains,
          differingDestination: differingDestination,
          lufthansa: lufthansa,
          replacedTrainLabel: replacedTrainNumbers,
          viaStops: viaStops,
        };

        departures.push(departure);
      }

      if (stationName === "" && data.length > 0) {
        stationName = data[0].station.name;
      }

      return { stationName: stationName, departures: departures };
    });
}

export const mergeCanceledAndReplacementTrains = (connections: any[]) => {
  const tempConnectionsArray = connections;

  for (let index = 0; index < tempConnectionsArray.length; index++) {
    const element = tempConnectionsArray[index];

    if (element.replacementFor.length > 0) {
      element.replacementFor.forEach((train: any) => {
        const canceledJourneyId = train.journeyID;

        if (index > 0) {
          const previousTrain = tempConnectionsArray[index - 1];

          if (previousTrain.journeyID === canceledJourneyId) {
            connections.splice(index - 1, 1);
          }
        }

        if (index < tempConnectionsArray.length - 1) {
          const nextTrain = tempConnectionsArray[index + 1];
          if (nextTrain.journeyID === canceledJourneyId) {
            connections.splice(index + 1, 1);
          }
        }
      });
    }
  }

  return connections;
};

export const chooseNumberOrLine = (train: any) => {
  if (train.line === null) {
    return train.number;
  } else {
    return train.line;
  }
};

export const createNotifications = (conn: any) => {
  var prioType = NotificationPrioType.none;
  var messages: string[] = [];

  const number = chooseNumberOrLine(conn.transport);

  if (conn.canceled === true) {
    var message = "Fahrt fällt aus -- Trip cancelled";

    if (conn.replacedBy.length > 0) {
      let trainNumbers = "";
      conn.replacedBy.forEach((train: any) => (trainNumbers = trainNumbers + `${train.category} ${chooseNumberOrLine(train)} `));

      message = `Fahrt fällt aus. Statt ${conn.transport.category} ${chooseNumberOrLine(conn.transport)} fährt heute ${trainNumbers} ---- Trip cancelled. Today ${trainNumbers} runs instead of ${
        conn.transport.category
      } ${number}`;
    }

    prioType = NotificationPrioType.error;
    messages.push(message);
  }

  if (conn.replacementFor.length > 0) {
    let trainNumbers = "";
    conn.replacementFor.forEach((train: any) => (trainNumbers = trainNumbers + `${train.category} ${chooseNumberOrLine(train)} `));

    var replacementMessage = `Statt ${trainNumbers} fährt heute ${conn.transport.category} ${chooseNumberOrLine(conn.transport)} -- Today ${
      conn.transport.category
    } ${number} runs instead of ${trainNumbers}`;

    if (!(prioType === NotificationPrioType.error)) {
      prioType = NotificationPrioType.info;
    }
    messages.push(replacementMessage);
  }

  if (conn.transport) {
    if (conn.transport.differingDestination) {
      var differingDestination = conn.transport.differingDestination;

      if (differingDestination !== undefined) {
        const message = `Endet heute in ${differingDestination.name} -- Ends today in ${differingDestination.name}`;

        if (prioType !== NotificationPrioType.error) {
          prioType = NotificationPrioType.warning;
        }
        messages.push(message);
      }
    }
  }

  if (conn.transport.via !== undefined && conn.transport.via.length > 0) {
    const canceledViaStops = conn.transport.via.filter((via: any) => via.canceled);

    if (conn.canceled) {
      conn.transport.via = conn.transport.via.map((via: any) => {
        via.canceled = false;
        return via;
      });
    }

    if (canceledViaStops.length === 1 && !conn.canceled) {
      if (prioType !== NotificationPrioType.error) {
        prioType = NotificationPrioType.warning;
      }
      messages.push(`Zwischenhalt entfällt -- Via stop cancelled`);
    } else if (canceledViaStops.length > 1 && !conn.canceled) {
      if (prioType !== NotificationPrioType.error) {
        prioType = NotificationPrioType.warning;
      }
      messages.push(`Zwischenhalte entfallen -- Via stops cancelled`);
    }
  }

  if (conn.platform !== conn.platformSchedule) {
    const message = `Heute von Gleis ${conn.platform} -- At platform ${conn.platform} today`;

    if (!(prioType === NotificationPrioType.error || prioType === NotificationPrioType.warning)) {
      prioType = NotificationPrioType.info;
    }
    messages.push(message);
  }

  if (conn.travelsWith.length > 0) {
    var originalSeparationMessage = "";

    if (conn.travelsWith[0].separationAt?.name) {
      originalSeparationMessage = "in " + conn.travelsWith[0].separationAt?.name;
    }
    //  var secondNotification = JSON.parse(JSON.stringify(notification));
    if (!(prioType === NotificationPrioType.error || prioType === NotificationPrioType.warning || prioType === NotificationPrioType.info) && conn.travelsWith[0].separationAt !== null) {
      prioType = NotificationPrioType.splitTrain;
    }
    if (conn.travelsWith[0].separationAt !== null) {
      messages.push(`Zugteilung ${originalSeparationMessage} -- Train Divided ${originalSeparationMessage}`);
    } else {
      // messages.push("");
    }
    //  companionNotifications.push(notification);
  }

  return { prioType: prioType, messages: messages };
};

export const getLufthansaTrain = (travelMessages: any, codeshares: any[]) => {
  var lufthansaTrain = "";
  if (travelMessages !== undefined && Array.isArray(travelMessages)) {
    for (let j = 0; j < travelMessages.length; j++) {
      const travelMessage = travelMessages[j];
      if (travelMessage.code !== undefined && travelMessage.text !== undefined) {
        if (travelMessage.code === "1000" && travelMessage.text.includes("Lufthansa Express")) {
          const lufthansaArray = travelMessage.text.split(" - ");

          if (lufthansaArray.length > 0) {
            lufthansaTrain = lufthansaArray[0];
          }

          if (lufthansaTrain.includes(" +++ ")) {
            const lufthansaMessageArray = lufthansaTrain.split(" +++ ");
            lufthansaTrain = lufthansaMessageArray[lufthansaMessageArray.length - 1];
          }
        }
      }
    }
  }

  if (lufthansaTrain === "") {
    codeshares.forEach((codeshare) => {
      if (codeshare.airlineCode && codeshare.flightnumber) {
        lufthansaTrain = codeshare.airlineCode + " " + codeshare.flightnumber;
      }
    });
  }

  return lufthansaTrain;
};