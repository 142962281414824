import { isStringNumber, checkRange } from "./helpers"

export const checkEvaNumbers = (evaNumbers) => {
  const evaNumbersArray = evaNumbers.split(",");
  let valid = true;
  evaNumbersArray.forEach((evaNumber) => {
    if (isStringNumber(evaNumber)) {
      if (evaNumber.length !== 7) {
        valid = false;
      }
    } else {
      valid = false;
    }
  });
  return valid;
};

export const checkFilterTransports = (transportString) => {
  const validTransports = [
    "HIGH_SPEED_TRAIN",
    "INTERCITY_TRAIN",
    "INTER_REGIONAL_TRAIN",
    "REGIONAL_TRAIN",
    "CITY_TRAIN",
    "SUBWAY",
    "TRAM",
    "BUS",
    "FERRY",
    "FLIGHT",
    "CAR",
    "TAXI",
    "SHUTTLE",
    "BIKE",
    "SCOOTER",
    "WALK",
    "UNKNOWN",
  ];
  const transportArray = transportString.split(",");
  let valid = true;
  transportArray.forEach((transport) => {
    if (!validTransports.includes(transport)) {
      valid = false;
    }
  });
  return valid;
};

export const checkIncludeStationGroup = (includeStationGroup) => {
  if (includeStationGroup === "true" || includeStationGroup === "false") {
    return true;
  } else {
    return false;
  }
};

export const checkTimeSpan = (timeSpan) => {
  if (isStringNumber(timeSpan)) {
    return checkRange(timeSpan, 60, 240);
  } else {
    return false;
  }
};

export const checkPages = (pages) => {
  if (isStringNumber(pages)) {
    return checkRange(pages, 0, 10);
  } else {
    return false;
  }
};