import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { checkEvaNumbers, checkFilterTransports, checkIncludeStationGroup, checkPages, checkTimeSpan } from "../utilities/urlParamValidations";
import { isStringNumber } from "../utilities/helpers";
import LandscapeMode from "../landscape/LandscapeMode";
import PortraitMode from "./portrait/PortraitMode";
import { ErrorCase } from "../data/ErrorCase";

export default function Main() {
  const [queryParams] = useSearchParams();

  const [appMode, setAppMode] = useState("lm");
  const [urlParams, setUrlParams] = useState("");
  const [orientation, setOrientation] = useState("landscape");
  const [maxPages, setMaxPages] = useState(3);
  const [maxViaStopsForRZ, setMaxViaStopsForRZ] = useState(4);
  const [timeSpan, setTimeSpan] = useState("180");

  const [errorCase, setErrorCase] = useState<ErrorCase | null>(null);

  useEffect(() => {
    var URLParams = "";

    const orientationFromUrl = queryParams.get("orientation");
    if (orientationFromUrl === "landscape" || orientationFromUrl === "portrait") {
      setOrientation(orientationFromUrl);
    }

    var appMode = "lm";
    const appModeFromUrl = queryParams.get("appMode");
    if (appModeFromUrl === "lm" || appModeFromUrl === "rz") {
      appMode = appModeFromUrl;
      setAppMode(appModeFromUrl);
    } else {
      setAppMode(appMode);
    }

    const evaNumbers = queryParams.get("evaNumbers");
    if (evaNumbers === null) {
      URLParams += "8000105";
    } else {
      if (checkEvaNumbers(evaNumbers)) {
        URLParams += queryParams.get("evaNumbers");
      } else {
        setErrorCase(ErrorCase.WRONG_PARAMETER_EVA_NUMBER);
        return;
      }
    }

    const filterTransports = queryParams.get("filterTransports");

    if (filterTransports === null) {
      URLParams += `?filterTransports=HIGH_SPEED_TRAIN,INTERCITY_TRAIN,INTER_REGIONAL_TRAIN,REGIONAL_TRAIN`;
    } else {
      if (checkFilterTransports(filterTransports)) {
        URLParams += `?filterTransports=${filterTransports}`;
      } else {
        setErrorCase(ErrorCase.WRONG_PARAMATER_FILTER_TRANSPORT);
        return;
      }
    }

    // URL Parameter: includeStationGroup check, default = false
    const includeStationGroup = queryParams.get("includeStationGroup");
    if (includeStationGroup === null) {
      URLParams += `&includeStationGroup=false`;
    } else {
      if (checkIncludeStationGroup(includeStationGroup)) {
        URLParams += `&includeStationGroup=${includeStationGroup}`;
      } else {
        setErrorCase(ErrorCase.WRONG_PARAMETER_STATION_GROUP);
        return;
      }
    }

    const timeSpan = queryParams.get("timeSpan");
    if (timeSpan !== null) {
      if (checkTimeSpan(timeSpan)) {
        setTimeSpan(timeSpan);
      } else {
        setErrorCase(ErrorCase.WRONG_PARAMATER_TIMESPAN);
        return;
      }
    }

    // if (appMode !== "rz") {
    //   URLParams += "&maxViaStops=5";
    // } else {
    const maxViaStops = queryParams.get("maxViaStops");
    if (maxViaStops === null) {
      URLParams += "&maxViaStops=4";
    } else {
      if (isStringNumber(maxViaStops)) {
        URLParams += "&maxViaStops=" + maxViaStops;
        //TODO: Max via stops for RZ
        var maxViaStopsForRZ = parseInt(maxViaStops);
        setMaxViaStopsForRZ(maxViaStopsForRZ);
      } else {
        URLParams += "&maxViaStops=4";
      }
    }
    // }

    // URL Parameter: pages check, default = 2, maxValue = 10

    // TODO: handle page logic
    let pages = queryParams.get("pages");
    let numberOfPagesInURl;
    if (pages === null) {
      numberOfPagesInURl = 3;
    } else {
      if (checkPages(pages)) {
        numberOfPagesInURl = parseInt(pages, 6);
      } else {
        // errorState.current = 6;
        setErrorCase(ErrorCase.WRONG_PARAMETER_PAGES);
        return;
      }

      setMaxPages(numberOfPagesInURl ? numberOfPagesInURl : 3);
    }

    setUrlParams(URLParams);
  }, [queryParams]);

  return (
    <div className="App">
      {orientation === "landscape" ? (
        <LandscapeMode maxPages={maxPages} urlParams={urlParams} appMode={appMode} errorCase={errorCase} timeSpan={timeSpan}></LandscapeMode>
      ) : (
        <PortraitMode urlParams={urlParams} maxViaStops={maxViaStopsForRZ} errorCase={errorCase} appMode={appMode} timeSpan={timeSpan} />
      )}
    </div>
  );
}
